import styled, { CSSObject } from "@emotion/styled";
import { css } from "@emotion/core";

// eslint-disable-next-line import/prefer-default-export
export const colors = {
  brandPrimary: "#fb651e",
  brandSecondary: "#f8a334",
  success: "#28a745",

  lightText: "#7f7f7f",
  mediumText: "#716f6f",

  backgroundBeige: "#f6f6ef",

  // Panes
  clickablePaneHover: "#f8f8f8",
};

export const fontSize = {
  normal: "16px",
  small: "13px",
};

export const paneBorder = "1px solid #c0c0c0";

export const mobileStyles = (styles: CSSObject): CSSObject => ({
  "@media (max-width: 991px)": styles,
});

export const mobileStyleCss = (styles: string) => css`
  @media (max-width: 991px) {
    ${styles}
  }
`;

export const round = (diameter: number): CSSObject => ({
  height: diameter,
  width: diameter,
  borderRadius: diameter / 2,
});

type ContextComponentProps = {
  otherStyles?: CSSObject;
};

export const DesktopOnly = styled.div<ContextComponentProps>(
  {
    display: "contents",
    ...mobileStyles({
      display: "none",
    }),
  },
  (props) => ({
    ...(props.otherStyles || {}),
  })
);

export const MobileOnly = styled.div<ContextComponentProps>(
  {
    display: "none",
  },
  (props) => ({
    ...mobileStyles({
      display: "contents",
      ...(props.otherStyles || {}),
    }),
  })
);
