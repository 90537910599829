import React from "react";
import { css, jsx } from "@emotion/core";
import check from "@fortawesome/fontawesome-free/svgs/solid/check.svg";
import checkCircle from "@fortawesome/fontawesome-free/svgs/solid/circle-check.svg";
import circle from "@fortawesome/fontawesome-free/svgs/regular/circle.svg";
import course from "@fortawesome/fontawesome-free/svgs/solid/graduation-cap.svg";
import handshake from "@fortawesome/fontawesome-free/svgs/solid/handshake.svg";
import penAlt from "@fortawesome/fontawesome-free/svgs/solid/pen-clip.svg";
import plus from "@fortawesome/fontawesome-free/svgs/solid/plus.svg";
import showcase from "@fortawesome/fontawesome-free/svgs/solid/chalkboard.svg";
import sprint from "@fortawesome/fontawesome-free/svgs/solid/person-running.svg";
import times from "@fortawesome/fontawesome-free/svgs/solid/xmark.svg";
import envelope from "@fortawesome/fontawesome-free/svgs/solid/envelope.svg";
import search from "@fortawesome/fontawesome-free/svgs/solid/magnifying-glass.svg";
import bookOpen from "@fortawesome/fontawesome-free/svgs/solid/book-open.svg";
import school from "@fortawesome/fontawesome-free/svgs/solid/school.svg";
import star from "@fortawesome/fontawesome-free/svgs/solid/star.svg";
import hammer from "@fortawesome/fontawesome-free/svgs/solid/hammer.svg";
import trophy from "@fortawesome/fontawesome-free/svgs/solid/trophy.svg";
import about from "./icon-about.svg";
import admin from "./icon-admin.svg";
import chevron from "./icon-chevron.svg";
import curriculum from "./icon-curriculum.svg";
import dashboard from "./icon-dashboard.svg";
import deals from "./icon-deals.svg";
import directory from "./icon-directory.svg";
import forum from "./icon-forum.svg";
import groupSessions from "./icon-group-sessions.svg";
import help from "./icon-help.svg";
import library from "./icon-library.svg";
import link from "./icon-link.svg";
import logo from "./logo.svg";
import menu from "./menu.svg";
import more from "./icon-more.svg";
import signIn from "./icon-sign-in.svg";
import signOut from "./icon-sign-out.svg";
import updates from "./icon-updates.svg";
import ycY from "./yc-y.svg";

/* eslint-disable global-require */

const typeToIcon = {
  about,
  admin,
  bookOpen,
  check,
  checkCircle,
  circle,
  chevron,
  course,
  curriculum,
  dashboard,
  deals,
  directory,
  forum,
  groupSessions,
  handshake,
  help,
  library,
  link,
  logo,
  envelope,
  hammer,
  menu,
  more,
  penAlt,
  plus,
  school,
  search,
  showcase,
  signIn,
  signOut,
  sprint,
  star,
  times,
  trophy,
  updates,
  ycY,
};

export type IconType = keyof typeof typeToIcon;

const Icon: React.FC<JSX.IntrinsicElements["svg"] & { icon: IconType; keepColor?: boolean }> = ({
  icon,
  keepColor = false,
  css: cssOverrides,
  ...props
}) => {
  if (!typeToIcon[icon]) return null;

  return jsx(typeToIcon[icon], {
    css: [!keepColor && css({ height: "100%", path: { fill: "currentColor" } }), cssOverrides],
    ...props,
  });
};

export default Icon;
