import { css } from "@emotion/core";
import styled, { CSSObject } from "@emotion/styled";
import React from "react";
import { paneBorder } from "../styles";
import Icon, { IconType } from "./icon";

export const PaneHeader = styled.div({
  padding: 28,
  paddingBottom: 17,
  borderBottom: paneBorder,
  display: "flex",
  alignItems: "center",
  "h1, h2, h3, h4": { margin: 0 },
});

export const PaneItem = styled.div`
  padding: 28px;
  border-bottom: ${paneBorder};
  &:last-child {
    border-bottom: 0;
  }
`;

type Props = {
  title?: string;
  titleIcon?: IconType;
  header?: React.ReactElement;
  link?: { text: string; href: string; newTab?: boolean };
  headerRight?: React.ReactElement;
  maxHeight?: number;
  fullHeight?: boolean;
  noMarginBottom?: boolean;
  styles?: CSSObject;
} & JSX.IntrinsicElements["div"];

export const Pane = (props: Props) => {
  let { title, link, header, headerRight, maxHeight, fullHeight, noMarginBottom, ...otherProps } =
    props;

  if ((title || link || headerRight) && !header) {
    header = (
      <>
        <h3 css={css({ flex: 1, display: "flex" })}>
          {props.titleIcon && (
            <Icon icon={props.titleIcon} css={css({ height: "1.2em", marginRight: 7 })} />
          )}{" "}
          <span>{title}</span>
        </h3>
        {link && (
          <a href={link.href} target={link.newTab ? "_blank" : undefined}>
            {link.text}
          </a>
        )}
        {headerRight}
      </>
    );
  }

  return (
    <div
      {...otherProps}
      css={css({
        backgroundColor: "white",
        border: paneBorder,
        borderRadius: 4,
        width: "100%",
        ...(fullHeight ? { height: "100%" } : {}),
        marginBottom: noMarginBottom ? 0 : 25,
        h3: { marginBottom: 0, fontSize: "1.125rem", fontWeight: "bold" },
        "> :first-of-type": {
          borderTopLeftRadius: 3,
          borderTopRightRadius: 3,
        },
        "> :last-child": {
          borderBottomLeftRadius: 3,
          borderBottomRightRadius: 3,
        },
        ...(maxHeight ? { maxHeight, overflow: "scroll" } : {}),
        ...(props.styles || {}),
      })}
    >
      {header && <PaneHeader>{header}</PaneHeader>}
      {props.children}
    </div>
  );
};
